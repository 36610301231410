import styled from "@emotion/styled";
import {
  Attribute,
  createSchemaDefinition,
  schemaJSON,
} from "@mml-io/mml-schema";
import { DOMSanitizer } from "@mml-io/networked-dom-web";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import * as domUtils from "~/library/domUtils";
import PanelHeading from "~/screens/Editor/components/PanelHeading";
import { InspectorColorPicker } from "~/screens/Editor/panels/Inspector/InspectorColorPicker";
import { InspectorInput } from "~/screens/Editor/panels/Inspector/InspectorInput";
import {
  NewAttributeButton,
  NewAttributeContainer,
  NewAttributeSection,
} from "~/screens/Editor/panels/Inspector/NewAttributeSection";

const schemaDefinition = createSchemaDefinition(schemaJSON);

const Container = styled.div(({ theme }) => ({
  flex: "1",
  padding: theme.spacing(2),
  overflowY: "scroll",
  paddingBottom: 65,
}));

const ElementName = styled.h2({
  fontSize: 20,
  fontWeight: 500,
  display: "block",
  margin: 0,
});

const ElementLink = styled(Link)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  display: "inline-flex",
  color: theme.palette.primary.main,
  textDecoration: "none",
  alignItems: "center",

  "&:hover": {
    textDecoration: "underline",
  },

  "& svg": {
    marginLeft: 4,
  },
}));

const InspectorRow = styled.div({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  borderRadius: 4,
  gap: 5,
  marginTop: 8,
});

export const InspectorTextContainer = styled.div({
  marginTop: 8,
  marginBottom: 8,
});

const OtherAttributeContainer = styled(NewAttributeContainer)({
  marginBottom: 8,
});

const RemoveAttributeButton = styled(NewAttributeButton)({
  paddingTop: 3,
});

const ELEMENT_ATTRIBUTE_GROUPING_CONFIG = {
  "m-cube": [["width", "height", "depth"]],
  "m-cylinder": [["radius", "height", "opacity"]],
  "m-plane": [["height", "width", "opacity"]],
  "m-sphere": [["radius", "opacity"]],
  "m-model": [["src"], ["anim"], ["anim-start-time"], ["anim-pause-time"]],
  "m-character": [["src"], ["anim"], ["anim-start-time"], ["anim-pause-time"]],
  "m-image": [["src"], ["width", "height", "opacity"]],
  "m-video": [["src"], ["width", "height"], ["start-time", "pause-time"]],
  "m-audio": [["src"], ["volume"], ["start-time", "pause-time"]],
  "m-position-probe": [["range", "interval"]],
  "m-label": [
    ["content"],
    ["font-size", "font-color"],
    ["width", "height"],
    ["padding"],
  ],
  "m-light": [["type"], ["intensity"]],
} as { [key: string]: string[][] };

const ATTRIBUTE_GROUP_SORTING_GROUPING_CONFIG: [string, string[][]][] = [
  [
    "transformable",
    [
      ["x", "y", "z"],
      ["rx", "ry", "rz"],
      ["sx", "sy", "sz"],
    ],
  ],
  ["colorable", []],
  ["debuggable", []],
  ["collideable", []],
  ["shadows", []],
];

const getSortedOwnAttributeList = (elementName: string) => {
  if (!elementName) return [[], {}] as const;
  const elementDefinition = schemaDefinition.elements[elementName];
  if (!elementDefinition) return [[], {}] as const;

  const sortedAttributeList: Attribute[][] = [];
  const attributeListByKey: { [name: string]: true } = {};

  const elementMMLAttributesByKey = elementDefinition.attributes.reduce(
    (attributes, attribute) => {
      attributes[attribute.name] = attribute;
      return attributes;
    },
    {} as { [key: string]: Attribute },
  );

  ELEMENT_ATTRIBUTE_GROUPING_CONFIG[elementName]?.forEach((row) => {
    const rowWithAttributes: Attribute[] = [];
    row.forEach((attribute) => {
      attributeListByKey[attribute] = true;
      rowWithAttributes.push(elementMMLAttributesByKey[attribute]);
      delete elementMMLAttributesByKey[attribute];
    });
    sortedAttributeList.push(rowWithAttributes);
  });

  // add the remaining attributes
  Object.keys(elementMMLAttributesByKey).forEach((attribute) => {
    attributeListByKey[attribute] = true;
    sortedAttributeList.push([elementMMLAttributesByKey[attribute]]);
  });

  return [sortedAttributeList, attributeListByKey] as const;
};

const getSortedAttributeGroupList = (
  elementAttributeGroups: (typeof schemaDefinition.elements)[string]["attributeGroups"],
) => {
  if (!elementAttributeGroups.length) return [[], {}] as const;

  const elementAttributeGroupsByKey = elementAttributeGroups.reduce(
    (attributeGroup, group) => {
      if (group === "coreattrs") return attributeGroup;

      attributeGroup[group] = schemaDefinition.attributeGroups[
        group
      ].attributes.reduce(
        (attributes, attribute) => {
          attributes[attribute.name] = attribute;
          return attributes;
        },
        {} as { [key: string]: Attribute },
      );
      return attributeGroup;
    },
    {} as { [key: string]: { [key: string]: Attribute } },
  );

  const orderedAttributeList: [string, Attribute[][]][] = [];
  const attributeListByKey: { [name: string]: true } = {};

  ATTRIBUTE_GROUP_SORTING_GROUPING_CONFIG.forEach(([group, groupSorting]) => {
    const attributeGroup = elementAttributeGroupsByKey[group];

    if (!attributeGroup) return;

    const orderedCurrentAttributeGroup: Attribute[][] = [];

    // add the attributes in the order specified by the groupSorting
    groupSorting.forEach((row) => {
      const rowWithAttributes: Attribute[] = [];
      row.forEach((attribute) => {
        attributeListByKey[attribute] = true;
        rowWithAttributes.push(attributeGroup[attribute]);
        delete attributeGroup[attribute];
      });
      orderedCurrentAttributeGroup.push(rowWithAttributes);
    });

    // add the remaining attributes
    Object.keys(attributeGroup).forEach((attribute) => {
      attributeListByKey[attribute] = true;
      orderedCurrentAttributeGroup.push([attributeGroup[attribute]]);
    });

    orderedAttributeList.push([group, orderedCurrentAttributeGroup]);

    delete elementAttributeGroupsByKey[group];
  });

  // add the remaining attribute groups
  Object.keys(elementAttributeGroupsByKey).forEach((group) => {
    const attributes: Attribute[][] = [];
    Object.values(elementAttributeGroupsByKey[group]).forEach((attribute) => {
      attributes.push([attribute]);
      attributeListByKey[attribute.name] = true;
    });

    orderedAttributeList.push([group, attributes]);
  });

  return [orderedAttributeList, attributeListByKey] as const;
};

const capitalize = (str: string) =>
  str.slice(0, 1).toUpperCase() + str.slice(1);

const InspectorPanel = () => {
  const focusRef = useRef<string | null>(null);
  const [elementPath, setElementPath] = useState<number[] | null | undefined>(
    null,
  );

  useEffect(() => {
    if (!focusRef.current) return;

    const input: HTMLInputElement | null = document.querySelector(
      `textarea#${focusRef.current}`,
    );
    if (input) {
      input.focus();
      focusRef.current = null;
    }
  }, [focusRef.current]);

  useEffect(() => {
    const selectedElement = appState.project?.selectedElements?.[0];

    if (!selectedElement || !appState.project?.remoteHolderElement) {
      setElementPath(null);
      return;
    }

    const elemPath = domUtils.elementToPath(
      appState.project.remoteHolderElement,
      selectedElement,
    );

    setElementPath(elemPath);
  }, [
    appState.project?.selectedElements,
    appState.project?.files?.[0]?.content,
  ]);

  const handleUpdateValue = useCallback(
    (value: string | null, attribute: string, isOtherAttribute?: boolean) => {
      // sending null deletes the attribute, which is fine for schema attributes but not for the custom ones
      const updatedValue = !value && !isOtherAttribute ? null : value;

      appState.project?.updateElementFromSourceDocument(
        elementPath as number[],
        {
          [attribute]: updatedValue,
        },
      );
    },
    [elementPath],
  );

  // Get same element in the new DOM using the path
  const updatedDocumentHolder = appState.project?.getContentAsElem() || null;
  const element =
    updatedDocumentHolder && elementPath
      ? domUtils.pathToElement(updatedDocumentHolder, elementPath)
      : null;

  const elementName = element?.tagName.toLowerCase();
  const elementPathKey = elementPath?.join(",");
  const elementDefinition = schemaDefinition.elements[elementName as string];

  const [sortedOwnAttributeList, ownAttributeByKey] = useMemo(
    () => getSortedOwnAttributeList(elementName ?? ""),
    [elementPathKey],
  );

  const [sortedAttributeList, attributeGroupByKey] = useMemo(
    () => getSortedAttributeGroupList(elementDefinition?.attributeGroups ?? []),
    [elementPathKey],
  );

  if (!elementPath || !appState.project || !element) return null;

  const removeAttribute = (targetAttribute: string) => {
    appState.project?.updateElementFromSourceDocument(elementPath, {
      [targetAttribute]: null,
    });
  };

  const handleAddAttribute = (newAttribute: string) => {
    if (!newAttribute) return false;

    if (!DOMSanitizer.shouldAcceptAttribute(newAttribute)) {
      return false;
    }

    appState.project?.updateElementFromSourceDocument(elementPath, {
      [newAttribute]: "",
    });
    focusRef.current = newAttribute;

    return true;
  };

  const otherAttributes = Object.values(element.attributes).filter(
    (attribute) =>
      !["id", "class"].includes(attribute.name) &&
      !(ownAttributeByKey as { [key: string]: true })[attribute.name] &&
      !(attributeGroupByKey as { [key: string]: true })[attribute.name],
  );

  return (
    <Container key={elementPathKey}>
      <ElementName>{"<" + elementName + ">"}</ElementName>
      <ElementLink
        to={"https://mml.io/docs/reference/elements/" + elementName}
        target="_blank"
      >
        mml.io/docs <Icon icon="openLink" size="16px" color="#808080" />
      </ElementLink>
      <InspectorTextContainer>
        <PanelHeading>MML Attributes</PanelHeading>
      </InspectorTextContainer>

      <InspectorRow>
        <InspectorInput
          value={element.getAttribute("id") ?? ""}
          attribute="id"
          type="xs:string"
          updateValue={handleUpdateValue}
        />
        <InspectorInput
          value={element.getAttribute("class") ?? ""}
          attribute="class"
          type="xs:string"
          updateValue={handleUpdateValue}
        />
      </InspectorRow>
      {sortedOwnAttributeList.map((attributeRow, i) => (
        <InspectorRow key={i}>
          {attributeRow.map((attributes) => {
            const { name, type, description } = attributes;

            return (
              <InspectorInput
                value={element.getAttribute(name) ?? ""}
                key={name}
                attribute={name}
                type={type}
                options={attributes.enum}
                updateValue={handleUpdateValue}
                description={description?.join("") as string}
              />
            );
          })}
        </InspectorRow>
      ))}
      {sortedAttributeList.map(([groupName, sortedList]) => {
        return (
          <>
            <InspectorTextContainer>
              <PanelHeading>{capitalize(groupName)}</PanelHeading>
            </InspectorTextContainer>
            {groupName === "colorable" ? (
              <InspectorRow>
                <InspectorColorPicker
                  value={element.getAttribute("color") ?? "#ffffff"}
                  updateValue={handleUpdateValue}
                  attribute="color"
                  description={"The color of the element"}
                />
              </InspectorRow>
            ) : (
              sortedList.map((attributeList, i) => (
                <InspectorRow key={i}>
                  {attributeList.map(({ name, type, description }) => (
                    <InspectorInput
                      value={element.getAttribute(name) ?? ""}
                      key={name}
                      attribute={name}
                      type={type}
                      updateValue={handleUpdateValue}
                      description={description?.join("") as string}
                    />
                  ))}
                </InspectorRow>
              ))
            )}
          </>
        );
      })}
      {!!otherAttributes.length && (
        <>
          <InspectorTextContainer>
            <PanelHeading>Other Attributes</PanelHeading>
          </InspectorTextContainer>
          {otherAttributes.map((attribute) => (
            <OtherAttributeContainer key={attribute.name}>
              <InspectorInput
                value={element.getAttribute(attribute.name) ?? ""}
                attribute={attribute.name}
                updateValue={handleUpdateValue}
                isOtherAttribute
              />
              <RemoveAttributeButton
                onClick={() => removeAttribute(attribute.name)}
              >
                <Icon icon="delete" size="18px" />
              </RemoveAttributeButton>
            </OtherAttributeContainer>
          ))}
        </>
      )}
      <NewAttributeSection handleAddAttribute={handleAddAttribute} />
    </Container>
  );
};

export default {
  id: "inspector",
  name: "Inspector",
  Component: observer(InspectorPanel),
};
