import styled from "@emotion/styled";
import {
  EditableNetworkedDOM,
  NetworkedDOM,
} from "@mml-io/networked-dom-document";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import MMLWebClient from "~/library/mml/client";
import { getIframeTargetWindow } from "~/library/mml/iframeTarget";
import { defaultClientScene } from "~/library/mml/sceneConfig";

const Container = styled.div(() => ({
  flex: "1",
  backgroundColor: "#222222",
}));

const ClientView = styled.div(() => ({
  width: "100%",
  height: "100%",
}));

const PlayView = ({
  url,
  localDocument,
  onClientCreated,
}: {
  url?: string;
  localDocument?: NetworkedDOM | EditableNetworkedDOM;
  onClientCreated?: (client: MMLWebClient) => void;
}) => {
  const [client, setClient] = useState<MMLWebClient | null>(null);

  const elementRef = useRef<HTMLDivElement>(null);

  const fitContainer = useCallback(() => {
    client?.fitContainer();
  }, [client]);

  useEffect(() => {
    let disposed = false;
    let runnerClient: MMLWebClient | null = null;

    getIframeTargetWindow().then(async (wrapper) => {
      if (disposed) return;

      let remoteHolderElement =
        wrapper.iframeDocument.getElementById("play-view");
      if (!remoteHolderElement) {
        remoteHolderElement = wrapper.iframeDocument.createElement("div");
        remoteHolderElement.id = "play-view";
        wrapper.iframeDocument.body.append(remoteHolderElement);
      }

      runnerClient = await MMLWebClient.create(
        wrapper.iframeWindow,
        remoteHolderElement,
        true,
      );
      if (disposed) {
        runnerClient.dispose();
        return;
      }

      defaultClientScene(runnerClient.mScene);
      setClient(runnerClient);

      onClientCreated && onClientCreated(runnerClient);
    });

    return () => {
      disposed = true;
      if (runnerClient) {
        runnerClient.dispose();
        setClient(null);
      }
    };
  }, [onClientCreated]);

  useEffect(() => {
    window.addEventListener("resize", fitContainer);

    return () => {
      window.removeEventListener("resize", fitContainer);
    };
  }, [client, fitContainer]);

  useEffect(() => {
    if (elementRef.current && client) {
      elementRef.current.appendChild(client.element);
      fitContainer();
    }
  }, [elementRef.current, client, fitContainer]);

  useEffect(() => {
    if (client) {
      if (url) {
        client.connectToSocket(url);
      } else if (localDocument) {
        client.connectToDocument(localDocument);
      }
    }

    return () => {
      if (client) {
        client.disconnect();
      }
    };
  }, [client, url, localDocument]);

  return (
    <Container>
      <ClientView ref={elementRef} />
    </Container>
  );
};

export default PlayView;
