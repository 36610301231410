import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";

import AppState from "~/library/appState";
import MMLWebClient from "~/library/mml/client";
import { getIframeTargetWindow } from "~/library/mml/iframeTarget";
import { defaultClientScene } from "~/library/mml/sceneConfig";

const ClientView = styled.div({
  height: "100%",
  minHeight: 0,
  minWidth: 0,
  position: "relative",
});

function FloatingClient({ children }: { children: ReactNode }) {
  const [client, setClient] = useState<MMLWebClient | null>(null);

  const { project } = AppState;

  const elementRef = useRef<HTMLDivElement>(null);

  const fitContainer = () => {
    client?.fitContainer();
  };

  useEffect(() => {
    if (client && project) {
      if (project.localDocument) {
        client.connectToDocument(project.localDocument);
      } else {
        console.log(
          "Connecting to socket ",
          `${window.serverConfig.EXECUTION_URL}/${project.id}`,
        );
        client.connectToSocket(
          `${window.serverConfig.EXECUTION_URL}/${project.id}`,
        );
      }
    } else {
      client?.disconnect();
    }
  }, [client, project]);

  useEffect(() => {
    let disposed = false;
    let runnerClient: MMLWebClient | null = null;

    getIframeTargetWindow().then(async (wrapper) => {
      if (!project || disposed) return;

      let remoteHolderElement =
        wrapper.iframeDocument.getElementById("play-panel-holder");
      if (!remoteHolderElement) {
        remoteHolderElement = wrapper.iframeDocument.createElement("div");
        remoteHolderElement.id = "play-panel-holder";
        wrapper.iframeDocument.body.append(remoteHolderElement);
      }

      runnerClient = await MMLWebClient.create(
        wrapper.iframeWindow,
        remoteHolderElement,
        true,
      );
      if (disposed) {
        runnerClient.dispose();
        return;
      }

      defaultClientScene(runnerClient.mScene);
      setClient(runnerClient);
    });

    return () => {
      disposed = true;
      if (runnerClient) {
        runnerClient.dispose();
        setClient(null);
      }
    };
  }, [project]);

  useEffect(() => {
    window.addEventListener("resize", fitContainer);
    window.addEventListener("editor-layout", fitContainer);

    return () => {
      window.removeEventListener("resize", fitContainer);
      window.removeEventListener("editor-layout", fitContainer);
    };
  }, [client]);

  useEffect(() => {
    if (elementRef.current && client) {
      elementRef.current.appendChild(client.element);
      fitContainer();
    }
  }, [elementRef.current, client]);

  return <ClientView ref={elementRef}>{children}</ClientView>;
}

export default observer(FloatingClient);
