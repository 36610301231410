import { MMLScene, RemoteDocumentWrapper } from "@mml-io/mml-web";
import {
  StandaloneThreeJSAdapter,
  StandaloneThreeJSAdapterControlsType,
} from "@mml-io/mml-web-threejs-standalone";
import {
  EditableNetworkedDOM,
  NetworkedDOM,
} from "@mml-io/networked-dom-document";
import {
  NetworkedDOMWebsocket,
  NetworkedDOMWebsocketStatus,
} from "@mml-io/networked-dom-web";
import { FakeWebsocket } from "@mml-io/networked-dom-web-runner";

import { NonInteractiveMMLScene } from "./NonInteractiveMMLScene";

export default class MMLWebClient {
  public readonly element: HTMLDivElement;
  private remoteDocumentHolder: HTMLElement;
  private disposed = false;

  remoteDocumentWrapper?: RemoteDocumentWrapper;
  mScene:
    | MMLScene<StandaloneThreeJSAdapter>
    | NonInteractiveMMLScene<StandaloneThreeJSAdapter>;

  private connectedState: {
    document?: NetworkedDOM | EditableNetworkedDOM;
    fakeWebsocket?: FakeWebsocket;
    domWebsocket: NetworkedDOMWebsocket;
  } | null = null;

  public static async create(
    windowTarget: Window,
    remoteHolderElement: HTMLElement,
    interactive: boolean,
  ): Promise<MMLWebClient> {
    const client = new MMLWebClient(
      windowTarget,
      remoteHolderElement,
      interactive,
    );
    await client.init();
    return client;
  }

  constructor(
    private windowTarget: Window,
    private remoteHolderElement: HTMLElement,
    private interactive: boolean,
  ) {
    this.windowTarget = windowTarget;
    this.remoteHolderElement = remoteHolderElement;

    // Create element the scene will be rendered in
    this.element = document.createElement("div");
    this.element.style.position = "relative";
    this.element.style.width = "100%";
    this.element.style.height = "100%";
    this.mScene = this.interactive
      ? new MMLScene(this.element)
      : new NonInteractiveMMLScene(this.element);
  }

  private async init() {
    const graphicsAdapter = await StandaloneThreeJSAdapter.create(
      this.element,
      {
        controlsType: StandaloneThreeJSAdapterControlsType.DragFly,
      },
    );

    if (!this.interactive) {
      // Disable clicking and audio for non-interactive scenes
      (graphicsAdapter as any).clickTrigger.dispose();
      graphicsAdapter.getAudioListener().setMasterVolume(0);
    }

    this.mScene.init(graphicsAdapter);

    // Create document wrapper to contain MML source
    const remoteDocumentWrapper = new RemoteDocumentWrapper(
      "http://localhost",
      this.windowTarget,
      this.mScene,
      (element, event) => {
        this.connectedState?.domWebsocket?.handleEvent(element, event);
      },
    );
    this.remoteDocumentWrapper = remoteDocumentWrapper;
    this.remoteDocumentHolder = remoteDocumentWrapper.remoteDocument;
    this.remoteHolderElement.append(this.remoteDocumentHolder);
    this.fitContainer();
  }

  public fitContainer() {
    this.mScene.fitContainer();
  }

  public dispose() {
    if (this.disposed) {
      return;
    }
    this.disposed = true;
    this.disconnect();
    this.remoteDocumentHolder.remove();
    this.element.remove();
    this.mScene.dispose();
  }

  public disconnect() {
    if (!this.connectedState) {
      return;
    }

    // Empty current document content
    this.remoteDocumentHolder.innerHTML = "";

    // Disconnect local document
    if (this.connectedState.document) {
      try {
        this.connectedState.document.removeWebSocket(
          this.connectedState.fakeWebsocket
            ?.serverSideWebsocket as unknown as WebSocket,
        );
      } catch (e) {
        // The document may have already been disposed
      }
    }

    // Disconnect real socket
    else {
      this.connectedState.domWebsocket.stop();
    }

    this.connectedState = null;
  }

  public getScreenshot() {
    const graphicsAdapter = this.mScene.getGraphicsAdapter();
    const renderer = graphicsAdapter.getRenderer();
    const camera = graphicsAdapter.getCamera();
    const threeScene = graphicsAdapter.getThreeScene();
    renderer.render(threeScene, camera);
    return renderer.domElement.toDataURL("image/jpeg");
  }

  private connectToWebSocket(url: string, factory: (url: string) => WebSocket) {
    return new NetworkedDOMWebsocket(
      url,
      factory,
      this.remoteDocumentHolder,
      (time: number) => {
        this.remoteDocumentWrapper?.setDocumentTime(time);
      },
      (status: NetworkedDOMWebsocketStatus) => {
        if (status === NetworkedDOMWebsocketStatus.Connected) {
          console.log("Socket connected");
        } else {
          console.log("Socket status", NetworkedDOMWebsocketStatus[status]);
        }
      },
    );
  }

  public connectToSocket(url: string) {
    if (this.connectedState) this.disconnect();

    const domWebsocket = this.connectToWebSocket(
      url,
      NetworkedDOMWebsocket.createWebSocket,
    );

    this.connectedState = {
      domWebsocket,
    };
  }

  public connectToDocument(document?: NetworkedDOM | EditableNetworkedDOM) {
    if (!document) return;
    if (this.connectedState) this.disconnect();

    const fakeWebsocket = new FakeWebsocket("networked-dom-v0.1");

    const domWebsocket = this.connectToWebSocket("ws://localhost", () => {
      setTimeout(() => {
        document.addWebSocket(
          fakeWebsocket.serverSideWebsocket as unknown as WebSocket,
        );
      }, 1);
      return fakeWebsocket.clientSideWebsocket as unknown as WebSocket;
    });

    this.connectedState = {
      document,
      fakeWebsocket,
      domWebsocket,
    };
  }
}
