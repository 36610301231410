import { IMMLScene, RemoteDocumentWrapper } from "@mml-io/mml-web";
import {
  EditableNetworkedDOM,
  NetworkedDOM,
} from "@mml-io/networked-dom-document";
import {
  NetworkedDOMWebsocket,
  NetworkedDOMWebsocketStatus,
} from "@mml-io/networked-dom-web";
import { NetworkedDOMWebRunnerClient } from "@mml-io/networked-dom-web-runner";

export class MMLCompositionClient {
  private windowTarget: Window;
  private mmlScene: IMMLScene;
  private remoteHolderElement: HTMLElement;
  private networkedDOMWebRunnerClient: NetworkedDOMWebRunnerClient;
  private remoteDocumentWrapper: RemoteDocumentWrapper;

  private connectedState: {
    webRunnerClient?: NetworkedDOMWebRunnerClient;
    domWebsocket?: NetworkedDOMWebsocket;
  } | null = null;

  constructor(
    windowTarget: Window,
    remoteHolderElement: HTMLElement,
    mmlScene: IMMLScene,
  ) {
    this.windowTarget = windowTarget;
    this.remoteHolderElement = remoteHolderElement;
    this.mmlScene = mmlScene;

    let overriddenHandler:
      | ((element: HTMLElement, event: CustomEvent) => void)
      | null = null;
    const eventHandler = (element: HTMLElement, event: CustomEvent) => {
      if (!overriddenHandler) {
        throw new Error("overriddenHandler not set");
      }
      overriddenHandler(element, event);
    };
    this.remoteDocumentWrapper = new RemoteDocumentWrapper(
      window.location.href,
      this.windowTarget,
      this.mmlScene,
      eventHandler,
    );
    this.remoteHolderElement.append(this.remoteDocumentWrapper.remoteDocument);
    overriddenHandler = (element: HTMLElement, event: CustomEvent) => {
      if (!this.connectedState) {
        throw new Error("connectedState not set");
      }
      this.connectedState.domWebsocket?.handleEvent(element, event);
      this.connectedState.webRunnerClient?.connectedState?.domWebsocket?.handleEvent(
        element,
        event,
      );
    };

    this.networkedDOMWebRunnerClient = new NetworkedDOMWebRunnerClient(
      false,
      this.remoteDocumentWrapper.remoteDocument,
    );
  }

  public dispose() {
    this.disconnect();
    this.networkedDOMWebRunnerClient.dispose();
  }

  public disconnect() {
    if (!this.connectedState) {
      return;
    }

    // Disconnect local document
    if (this.connectedState.webRunnerClient) {
      this.connectedState.webRunnerClient.disconnect();
    }

    // Disconnect real socket
    else {
      this.connectedState.domWebsocket?.stop();
    }

    this.connectedState = null;
  }

  public connectToDocument(document: NetworkedDOM | EditableNetworkedDOM) {
    this.disconnect();
    this.networkedDOMWebRunnerClient.connect(document, (time: number) => {
      this.remoteDocumentWrapper.setDocumentTime(time);
    });
    this.connectedState = {
      webRunnerClient: this.networkedDOMWebRunnerClient,
    };
  }

  private connectToWebSocket(url: string, factory: (url: string) => WebSocket) {
    return new NetworkedDOMWebsocket(
      url,
      factory,
      this.remoteDocumentWrapper.remoteDocument,
      (time: number) => {
        this.remoteDocumentWrapper?.setDocumentTime(time);
      },
      (status: NetworkedDOMWebsocketStatus) => {
        if (status === NetworkedDOMWebsocketStatus.Connected) {
          console.log("Socket connected");
        } else {
          console.log("Socket status", NetworkedDOMWebsocketStatus[status]);
        }
      },
    );
  }

  public connectToSocket(url: string) {
    if (this.connectedState) this.disconnect();

    const domWebsocket = this.connectToWebSocket(
      url,
      NetworkedDOMWebsocket.createWebSocket,
    );

    this.connectedState = {
      domWebsocket,
    };
  }
}
