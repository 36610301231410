export function bodyFromRemoteHolderElement(
  remoteHolderElement: HTMLElement,
): HTMLElement {
  const body =
    remoteHolderElement.children[0]?.children[0]?.children[0]?.children[1];
  if (!body) {
    throw new Error("Remote holder element has no owner document body");
  }
  return body as HTMLBodyElement;
}

export const elementToPath = (
  remoteHolder: HTMLElement,
  element: HTMLElement,
) => {
  const root = bodyFromRemoteHolderElement(remoteHolder);
  const path = [];
  let currentElement: HTMLElement | undefined = element;
  while (currentElement && currentElement !== root) {
    const parentChildren = Array.from(
      currentElement?.parentElement?.children || [],
    );
    path.push(parentChildren.indexOf(currentElement));
    currentElement = currentElement.parentElement || undefined;
  }
  return path.reverse();
};

export const pathToElement = (root: HTMLElement, path: Array<number>) => {
  let currentElement: HTMLElement | undefined = root;
  path.forEach((i) => {
    currentElement = currentElement?.children[i] as HTMLElement;
  });
  return currentElement;
};
